<template>
    <div>MACHINE TODO</div>
</template>



<style lang="scss">
</style>

<script>

export default {
    data(){
        return{

        }
	},
	mounted:function()
	{
		//let root = this;

    },
    watch:{
        $route (){
            
        }
    },
    methods:{
		
    }
};

</script>